$red-color: #800e02;

body {
	background: $blue4;
	-webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;

}

*{
	box-sizing: border-box;
}

.title_font{
	font-family: 'Raleway', sans-serif;
	font-weight:500;
	letter-spacing: 1px;
}



h1, h2, h3, h4, h5 {
	@extend .title_font;
	text-transform: uppercase;
	letter-spacing: 1px;
}


h1 {
	@include font-size(22px);
	line-height: 28px;
	margin-bottom:30px;
	border-bottom:2px solid #e7eff6;
	padding:4px 0;
	display:inline-block;
	color:#e7eff6;
	font-weight: bold;

	@include tablet{ 
		@include font-size(30px);
		line-height: 32px;	
	}
}
h2 {
	@include font-size(22px);
	line-height: 26px;
	margin-bottom:22px;
	border-bottom:2px solid $blue1;
	padding:4px 0;
	display:inline-block;
	color:#e7eff6;
	font-weight: bold;

	@include tablet{ 
		@include font-size(26px);
		line-height: 28px;	
		margin-bottom: 40px;
	}
}
h3 {
	@include font-size(20px);
	line-height: 22px;
	margin-bottom:18px;
	border-bottom:1px solid #bac1ca;
	padding:3px 0;
	display:inline-block;
	color:#e7eff6;

	&.past-work__title {
		border:0;
	}
}
h4 {
	@include font-size(18px);
	line-height: 22px;
	margin-bottom:15px;
}

.btn {
	float: left;
	display: inline;
	background: #88919e;
	padding:10px;
	@extend .title_font;
	color:#e7eff6;
	@include font-size(12px);
	text-transform: uppercase;

	@include tablet{
		padding:10px 30px;
		@include font-size(14px);
	}

	@include desktop{
		span{
			position: relative;
			z-index: 2;
			transition:color 150ms linear;
		}
		&:before, &:after{
			position: absolute;
			left:0;
			width: 100%;
			height:0;
			content:' ';
			background: #4a4d51;
			transition:height 150ms linear;
			z-index:1;
		}

		&:before{
			top:0;

		}

		&:after{
			bottom:0;
		}

		&:hover{
			&:after, &:before{
				height: 50%;
			}
		}
	}
}

.inner {
    max-width:600px;
    width:100%;
	margin:auto;
	padding: 0 30px;
}

.width{

	float:left;
	width:100%;
	padding-left:$mobile-gutter;
	padding-right:$mobile-gutter;

	@include tablet{
		padding-left:$tablet-gutter;
		padding-right:$tablet-gutter;
	}

	@include desktop{
		padding-left:$desktop-gutter;
		padding-right:$desktop-gutter;
	}
}

a{
	color:#ffffff;
	position: relative;

	p &{
		&:after{
			content: ' ';
			position: absolute;
			bottom:-2px;
			left:0;
			width: 100%;
			height: 1px;
			background: #bac1ca;
		}

	}
}

.clearfix:after {
	visibility: hidden;
	display: block;
	font-size: 0;
	content: " ";
	clear: both;
	height: 0;
	}
* html .clearfix             { zoom: 1; } /* IE6 */
*:first-child+html .clearfix { zoom: 1; } /* IE7 */


section {
	width:100%;
	float: left;
	text-align: center;
}

p {
	@extend .copy_font;
	@include font-size(16px);
	line-height:20px;
	margin:0 auto 30px;

	@include tablet {
		@include font-size(20px);
		line-height: 28px;
	}

	&:last-child{
		margin-bottom: 0;
	}
}

img{
	display: block;
}




.work {
	float: left;
	display: inline;
	width: 100%;

	@include tablet {
		padding-left:$tablet-gutter;
		padding-right:$tablet-gutter;
	}

	@include desktop {
		padding-left:$desktop-gutter;
		padding-right:$desktop-gutter;	}

	&__item {
		float: left;
		display: inline;
		width: 100%;
		margin:50px 0 45px 0;
		position: relative;
		text-align: left;
		opacity: 0;
		transition: opacity 250ms linear, margin 250ms linear;

		@for $i from 1 through 20 {
			&:nth-child(#{$i}) {
				transition-delay:$i*0.2s;
			}
		}

		.visible & {
			opacity: 1;
			margin-top: 0;
		}

		img{
			float: left;
		}

		@include tablet {
			width: 48%;
			margin-right:4%;

			&:nth-child(2n+2){
				margin-right:0;
			}
		}

		@include desktop{
			width: 32%;
			margin-right: 2%;

			&:nth-child(2n+2){
				margin-right:2%;
			}


			&:nth-child(3n+2){
				margin-right: 0;
			}
		}

	}

	&__title {
		text-align: left;
		float: left;
		margin:20px $mobile-gutter 20px;

		@include tablet {
			margin-left:0;
			margin-right: 0;
		}
			
	}

	&__copy{
		float: left;
		display: block;
		clear: both;
		width: 90%;
		padding:0 $mobile-gutter;
		margin-bottom: 20px;

		@include tablet{
			width: 90%;
			padding:0;
			max-width: 90%;
		}
	}

	&__btn {
		margin-left:$mobile-gutter;

		@include tablet{
			margin-left:0;
		}
	}

	&__cms {
		position: absolute;
		top:-30px;
		right:0;
		z-index: 2;
	}
}

.past-work {

	&__list {
		@extend .body_font;
		color:#ffffff;
		margin-bottom: 30px;
	}

	&__item {
		margin-bottom: 8px;
		@include font-size(14px);
	}
}

