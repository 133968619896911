@import '../assets/sass/_mixins.scss';

.introduction{
    padding: 40px 0;
    background: $blue2;

    @include tablet{
        padding: 80px 0;
    }

    &__text{
        @include font-size(18px);
		line-height: 26px;
		

		@include tablet{
			@include font-size(26px);
			line-height: 32px;
			letter-spacing: 0.5px;
		}
    }
}

.inner {
    max-width:700px;
    width:100%;
    margin:auto;
    padding: 0 20px;
}

.backLink {
    float: left;
    width: 100%;
    padding: 40px 0;
    background: $blue2;
    text-align: center;
    @include font-size(22px);
	line-height: 28px;
	color:#e7eff6;
    font-weight: bold;
	text-transform: uppercase;
    letter-spacing: 1px;
    font-weight:bold;
    font-family: 'Raleway', sans-serif;

    @include tablet {
        @include font-size(20px);
        line-height: 28px;
        padding: 80px 0;
    }
}

.screenshots{
    text-align: center;
}

.screenshots img{
    margin: auto;
}

.workList{
    display: block;
    margin: 0 auto auto auto;
    font-family: 'Raleway', sans-serif;
    color: $blue1;
    padding: 20px 0 30px 0;
    background: $blue3;

    @include tablet{
        padding: 30px 0 50px 0;
    }
}

.workListItem__title{
    margin-top: 20px;  
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.8px;
  }

.workListItem__value{
  margin-top: 10px;  
}

.workListItem__value a{
    text-decoration:underline;
    color: $blue1;
}