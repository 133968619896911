$mobile-width:percentage(290/320);
$tablet-width:percentage(718/768);
$desktop-width:percentage(1480/1600);

$mobile-gutter:percentage(15/320);
$tablet-gutter:percentage(25/768);
$desktop-gutter:percentage(60/1600);

$blue1: #2a4d69;
$blue2: #4b86b4;
$blue3: #adcbe3;
$blue4: #e7eff6;
$blue5: #63ace5;

.body_font{
	font-family: 'Raleway', sans-serif;
	font-weight:400;
}

.copy_font{
	@extend .body_font;
	color:#e7eff6;
}

@function calculateRem($size) {
  $remSize: $size / 17px;
  @return $remSize * 1rem;
}

@mixin font-size($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin box-sizing ($args...){ 
  -webkit-box-sizing: $args; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: $args;    /* Firefox, other Gecko */
  box-sizing: $args;         /* Opera/IE 8+ */
}


@mixin tablet {
  @media only screen and (min-width: 580px) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: 1024px) {
    @content;
  }
}