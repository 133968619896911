@import '../assets/sass/_mixins.scss';
.welcome{
    padding: 40px 0;
    background: $blue2;

    @include tablet{
        padding: 80px 0;
    }

    &__intro{
        @include font-size(18px);
		line-height: 26px;
		

		@include tablet{
			@include font-size(26px);
			line-height: 32px;
			letter-spacing: 0.5px;
		}
    }
}

.inner {
    max-width:675px;
    width:100%;
	margin:auto;
	padding: 0 20px;
}

.work {
	float: left;
	display: inline;
    width: 100%;
    background: $blue4;
    color: $blue1;

	&__item {
		float: left;
		display: inline;
		width: 100%;
		position: relative;
        text-align: left;
        
		img{
            float: left;
            width: 100%;
            max-width: none;
		}

		@include tablet {
            width: 50%;
		}

		&:nth-child(4n+1){
			background-color: $blue3;
		}

		&:nth-child(4n+2){
			background-color: $blue4;
		}

		&:nth-child(4n+3){
			background-color: $blue1;
		}

		&:nth-child(4n+4){
			background-color: $blue2;
		}



	}

	&__title {
		float: left;
		color: #e7eff6;
		position: absolute;
		text-transform: uppercase;
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		background: rgba(42,77,105,0.4);
		display: flex;
		align-items: center;
		justify-content: center;
		font-family: 'Raleway', sans-serif;	
		transition: all linear 200ms;
		@include font-size(16px);
		line-height:20px;
		box-sizing: border-box;
		font-weight: bold;
		letter-spacing: 1px;

		@include tablet {
			@include font-size(20px);
			line-height: 28px;
		}

		@include desktop{
			opacity: 0;
			background: rgba(42,77,105,0.6);

			&:hover{
				opacity: 1;
			}
		}

		
	}

	&__copy{
		float: left;
		display: block;
		clear: both;
		width: 90%;
		padding:0 $mobile-gutter;
        margin-bottom: 20px;
        color: $blue1;

		@include tablet{
			width: 90%;
			padding:0;
			max-width: 90%;
		}
	}

	&__btn {
		margin-left:$mobile-gutter;

		@include tablet{
			margin-left:0;
		}
	}

	&__cms {
		position: absolute;
		top:-30px;
		right:0;
		z-index: 2;
    }
    
}

.workTitle{
    color: $blue1;
}

.workInner{
    max-width: 720px;
	margin: auto;
	text-align: center;
}

.workSingle{
	padding: 40px 0;
	float: left;
	width: 100%;

    @include tablet{
        padding: 80px 0;
	}
	
	&__title{
		@include font-size(18px);
		line-height: 26px;
		color: $blue1;
		

		@include tablet{
			@include font-size(26px);
			line-height: 32px;
			letter-spacing: 0.5px;
		}
		
	}

	&__description{
		

		p{
			color: $blue1;

			&:first-child{
				font-size: 18px;
				line-height: 26px;
			}
		}
	}

	&__btn{
		@extend .copy_font;
		@include font-size(16px);
		line-height:20px;
		margin:0 auto 30px;
		text-decoration: underline;
		margin-top:30px;
		color: $blue1;
		display: inline-block;
		font-weight: bold;

		@include tablet {
			@include font-size(20px);
			line-height: 28px;
		}
		
	}
}