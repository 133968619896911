@import '../assets/sass/_mixins.scss';

.mainFooter{
    padding: 40px 0;
    background: $blue1;
    width:100%;
	float: left;
	text-align: center;

    @include tablet{
        padding: 80px 0;
    }

    &__title{
        border-bottom-color: $blue4;
    }
}