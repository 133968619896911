@import '../assets/sass/_mixins.scss';

.mainHeader {
	float:left;
	display:block;
	width:100%;
	background: $blue1;
	padding: 40px 0;

	@include tablet{
		padding: 60px 0;
	}
}

.logo {
	background:url(../assets/logo.png) no-repeat;
	background-size: contain;
	display: block;
	width:200px;
	height:103px;
	text-indent:-9999em;
	margin:0 auto 0 auto;

	@media only screen and (min-width: 580px) {
		width: 300px;
		height: 155px;
	}

}